import * as React from "react"
import { setSessionPassword, getSessionPassword, getQueryPassword } from '../utils/utils';
import Footer from '../components/footer';
import Layout from "../components/layout";
import Projects from '../components/projects';
import PPIcon from "../images/icons/pp.inline.svg";
import RTIcon from "../images/icons/roadtrippers.inline.svg";
import UUIcon from "../images/icons/uula.inline.svg";
import SCIcon from "../images/icons/sc.inline.svg";
import CWIcon from "../images/icons/cw.inline.svg";
import TWIcon from "../images/icons/tw.inline.svg";
import WIcon from "../images/icons/w.inline.svg";
import SIcon from "../images/icons/s.inline.svg";
import AFIcon from "../images/icons/af.inline.svg";
import KLMIcon from "../images/icons/klm.inline.svg";
import AMIcon from "../images/icons/am.inline.svg";
import MIcon from "../images/icons/m.inline.svg";
import AIcon from "../images/icons/audi.inline.svg";
import WSIcon from "../images/icons/ws.inline.svg";

const isBrowser = typeof window !== "undefined"

const WorkPage = () => {
  if (isBrowser) {  
    if (window.location.search && !getSessionPassword()) {
      setSessionPassword(getQueryPassword(window.location).p);
    }
  }

  return (
    <Layout navtype="dark" title="Work">
      <div className="projects-intro">
        <h1>Work</h1>
        <p className="intro projects-inline">
          In the last 15+ years I've worked with innovative startups like <a rel="noreferrer" target="_blank" href="https://premiumparking.com" className="company"><PPIcon /> Premium Parking</a>, <a rel="noreferrer" target="_blank" href="https://roadtrippers.com" className="company"><RTIcon className='icons' /> Roadtrippers</a>, <a rel="noreferrer" target="_blank" href="https://uula.io" className="company"><UUIcon /> UULA</a>, <a rel="noreferrer" target="_blank" href="https://www.veracode.com" className="company"><SCIcon className='icons' /> SourceClear</a>, <a rel="noreferrer" target="_blank" href="https://www.clubworx.com" className="company"><CWIcon /> Clubworx</a>, <a rel="noreferrer" target="_blank" href="https://www.twiagemed.com" className="company"><TWIcon /> Twiage</a> and major companies like <a href="https://www.williams-sonoma.com" rel="noreferrer" target="_blank" className="company"><WSIcon className='icons' /> Williams-Sonoma</a>, <a rel="noreferrer" target="_blank" className="company" href="https://klm.com" title="KLM"><KLMIcon title="KLM" aria-label="KLM" /></a>, <a rel="noreferrer" target="_blank" href="https://www.westwoodenergy.com" className="company"><WIcon className='icons' /> Westwood Global Energy Group</a>, <span className="company"><AIcon className='icons' /> Audi (rus)</span>,  <a href="https://www.amtrak.com/" className="company" rel="noreferrer" target="_blank"><AMIcon />Amtrak</a>, <a href="https://wwws.airfrance.us" className="company" rel="noreferrer" target="_blank"><AFIcon />AirFrance</a>, <a href="https://www.scancafe.com" rel="noreferrer" target="_blank" className="company"><SIcon /> ScanCafe</a>, <MIcon />.
        </p>
        <p className="intro">
          Below is a sampling of some of the more in-depth projects I've done throughout the years.
        </p>
      </div>
      {getSessionPassword() === "Berlin" ? <Projects /> : ''}
      <Footer />
    </Layout>
  )
}

export default WorkPage
